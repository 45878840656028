import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import CurrentCallDisplay from "../current-call-display/current-call-display.component.jsx";
import { CurrentCallContext } from "../../contexts/current-call.context";
import { NumberToDialContext } from "../../contexts/number-to-dial.context";
import { PhoneStatusContext } from "../../contexts/phone-status.context";
import { DefaultDeviceContext } from "../../contexts/default-device.context.jsx";
import { SettingsContext } from "../../contexts/settings.context.jsx";
import { useAudioContext } from "../../contexts/audioContext.context.jsx";
import { normalizeNumber, formatE164 } from "../../utils/numberUtils.js";
import { useSIP } from "../../contexts/SIPContext";

import "./phone.styles.scss";

// const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
const keys = [
  { number: "1", letters: "" },
  { number: "2", letters: "ABC" },
  { number: "3", letters: "DEF" },
  { number: "4", letters: "GHI" },
  { number: "5", letters: "JKL" },
  { number: "6", letters: "MNO" },
  { number: "7", letters: "PQRS" },
  { number: "8", letters: "TUV" },
  { number: "9", letters: "WXYZ" },
  { number: "*", letters: "" },
  { number: "0", letters: "" },
  { number: "#", letters: "" },
];

function Phone({ onKeyPress }) {
  const [display, setDisplay] = useState("");
  const { numberToDial, setNumberToDial } = useContext(NumberToDialContext);
  const { defaultDevice } = useContext(DefaultDeviceContext);
  const { currentCall, setCurrentCall } = useContext(CurrentCallContext);
  const {
    isOnCall,
    // setIsOnCall
  } = useContext(PhoneStatusContext);
  const { keysMuted } = useContext(SettingsContext);
  const { playDTMFTone } = useAudioContext();
  const { makeCall, sendDTMF } = useSIP();
  const textFieldRef = useRef(null);

  useEffect(() => {
    const formattedNumber = formatE164(normalizeNumber(numberToDial));
    setDisplay(formattedNumber);
  }, [numberToDial]);

  const setPhoneOnCall = () => {
    const normalizedNumber = normalizeNumber(numberToDial); // Normalize the number

    setCurrentCall({
      display_name: normalizedNumber, // Use the normalized number
      display_number: normalizedNumber, // Use the normalized number
      call_direction: "outbound",
    });

    makeCall(
      normalizedNumber.includes("+")
        ? normalizedNumber
        : `sip:${normalizedNumber}@${defaultDevice.realm}`
    );

    // setIsOnCall(true);
  };

  // const handleKeyPress = (digit) => {
  //   if (!keysMuted) playDTMFTone(digit);
  //   !isOnCall
  //     ? setNumberToDial(normalizeNumber(numberToDial + digit))
  //     : sendDTMF(digit);
  //   // setNumberToDial(numberToDial + digit);
  // };

  // const handleDelete = () => {
  //   setNumberToDial((numberToDial) => numberToDial.slice(0, -1));
  // };

  const handleKeyPress = (digit) => {
    const inputElement = textFieldRef.current;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    if (!keysMuted) playDTMFTone(digit);

    if (!isOnCall) {
      if (start !== end) {
        // Replace selected text with the pressed digit
        const newValue =
          numberToDial.slice(0, start) + digit + numberToDial.slice(end);
        setNumberToDial(newValue);
        // Move the cursor to the correct position
        setTimeout(() => {
          inputElement.setSelectionRange(start + 1, start + 1);
        }, 0);
      } else {
        // No selection, just append the digit to the end
        const newValue = numberToDial + digit;
        setNumberToDial(newValue);
      }
    } else {
      sendDTMF(digit);
    }
  };

  const handleDelete = () => {
    const inputElement = textFieldRef.current;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    if (start !== end) {
      // Delete the selected text
      const newValue = numberToDial.slice(0, start) + numberToDial.slice(end);
      setNumberToDial(newValue);
    } else {
      // No selection, just delete the last character
      setNumberToDial((prev) => prev.slice(0, -1));
    }
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     setPhoneOnCall();
  //   } else if (event.key === "Backspace") {
  //     handleDelete();
  //   } else if (event.key === "+" || event.key === "*") {
  //     setNumberToDial((prev) => prev + event.key);
  //   } else if (!isNaN(parseInt(event.key))) {
  //     if (!keysMuted) playDTMFTone(event.key);
  //     // Only capture number keys
  //     setNumberToDial((prev) => prev + event.key);
  //   }
  // };

  const handleKeyDown = (event) => {
    const inputElement = textFieldRef.current;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    if (event.key === "Enter") {
      event.preventDefault();
      setPhoneOnCall();
    } else if (event.key === "Backspace" || event.key === "Delete") {
      event.preventDefault();
      if (start !== end) {
        // Delete selected text
        const newValue = numberToDial.slice(0, start) + numberToDial.slice(end);
        setNumberToDial(newValue);
      } else {
        // No selection, delete the last character
        handleDelete();
      }
    } else if (event.key === "+" || event.key === "*") {
      setNumberToDial((prev) => prev + event.key);
    } else if (!isNaN(parseInt(event.key))) {
      if (!keysMuted) playDTMFTone(event.key);
      setNumberToDial((prev) => prev + event.key);
    }
  };

  const handlePaste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text");
    const normalizedPaste = normalizeNumber(paste); // Normalize the pasted number if needed
    // Update the numberToDial here
    setNumberToDial(normalizedPaste);
    event.preventDefault(); // Prevent the default paste behavior
  };

  return (
    <Box>
      {isOnCall ? (
        <CurrentCallDisplay
          isOpen={isOnCall}
          callerName={currentCall.display_name}
          callerID={currentCall.display_number}
        />
      ) : (
        ""
      )}
      {/* <CurrentCallDisplay
        isOpen={isOnCall}
        // callerName={currentCall.display_name}
        // callerID={currentCall.display_number}
      /> */}
      <TextField
        fullWidth
        inputRef={textFieldRef}
        value={display}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        variant="standard"
        InputProps={{
          style: { textAlign: "center" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleDelete} edge="end">
                <BackspaceIcon sx={{ transform: "scale(0.9)" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ width: "100%", marginBottom: 3 }}
      />
      {/* <Box className="dialpad-container">
        {keys.map((key, index) => (
          <Button
            className="keypad-button"
            key={index}
            variant="contained"
            onClick={() => handleKeyPress(key)}
          >
            {key}
          </Button>
        ))}
      </Box> */}
      <Box className="dialpad-container">
        {keys.map((key, index) => (
          <Button
            className="keypad-button"
            key={index}
            variant="contained"
            onClick={() => handleKeyPress(key.number)}
            sx={{ flexDirection: "column", padding: "8px" }}
          >
            <span>{key.number}</span>
            {key.letters && (
              <span
                style={{ fontSize: "0.5rem", marginTop: "0.5px", opacity: 0.7 }}
              >
                {key.letters}
              </span>
            )}
          </Button>
        ))}
      </Box>
    </Box>
  );
}

export default Phone;
